var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col" }, [
    _c(
      "nav",
      {
        staticClass:
          "flex pl-4 pr-3 mt-3 pb-2 border-b border-blue-500 items-end"
      },
      [
        _c(
          "router-link",
          {
            staticClass: "w-1/3 flex justify-start items-center",
            attrs: { to: { name: "incidents-overview" } }
          },
          [
            _c("img", {
              staticClass: "w-7 h-7",
              attrs: {
                src: require("@/assets/images/close.svg"),
                alt: _vm.$t("general.button_close")
              }
            })
          ]
        ),
        _c("p", {
          staticClass:
            "w-1/3 flex justify-center font-semibold pointer-events-none",
          domProps: {
            textContent: _vm._s(
              _vm.$t("the_incident_creation.button_add_report")
            )
          }
        }),
        _c("div", { staticClass: "w-1/3 flex justify-end" }, [
          _c("button", {
            staticClass: "btn btn-blue rounded-full py-1 px-2",
            class: { "btn-loading": _vm.loading },
            attrs: { disabled: _vm.loading },
            domProps: { textContent: _vm._s(_vm.$t("general.button_save")) },
            on: { click: _vm.onSubmit }
          })
        ])
      ],
      1
    ),
    _c("ul", { staticClass: "list-disc list-inside" }, [
      _vm.error
        ? _c("li", {
            staticClass: "form-error",
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e(),
      _vm.errors.has("position")
        ? _c("li", {
            staticClass: "form-error",
            domProps: { textContent: _vm._s(_vm.errors.first("position")) }
          })
        : _vm._e(),
      _vm.errors.has("incident_type")
        ? _c("li", {
            staticClass: "form-error",
            domProps: { textContent: _vm._s(_vm.errors.first("incident_type")) }
          })
        : _vm._e()
    ]),
    _c(
      "form",
      {
        staticClass: "flex-grow flex flex-col",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "flex-grow" },
          [
            _c("div", { staticClass: "form-fieldset" }, [
              _c("p", {
                staticClass: "form-input-label",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_incident_creation.label_title")
                  )
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.incident.title,
                    expression: "incident.title"
                  }
                ],
                ref: "title",
                staticClass: "form-input",
                attrs: {
                  type: "text",
                  name: "title",
                  autofocus: "",
                  placeholder: _vm.$t("general.placeholder_optional")
                },
                domProps: { value: _vm.incident.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.incident, "title", $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-fieldset mt-4" }, [
              _c("p", {
                staticClass: "form-input-label text-gray-500",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_incident_creation.label_report_type")
                  )
                }
              })
            ]),
            _vm.incidentTypes !== null
              ? _c(
                  "div",
                  _vm._l(_vm.incidentTypes, function(type) {
                    return _c(
                      "label",
                      { key: type.id, staticClass: "detail-field" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current w-7 h-7",
                              style: { color: type.color },
                              attrs: { viewBox: "0 0 40 40" }
                            },
                            [
                              _c("circle", {
                                attrs: { cx: "20", cy: "20", r: "20" }
                              })
                            ]
                          ),
                          _c("p", {
                            staticClass: "detail-field-label ml-3",
                            domProps: { textContent: _vm._s(type.name) }
                          })
                        ]),
                        _c("v-radio", {
                          attrs: {
                            name: "incident_type",
                            validation: "required|min:1",
                            checked:
                              _vm.incident.incidentTypes.length > 0 &&
                              type.id === _vm.incident.incidentTypes[0].id
                          },
                          on: {
                            input: function($event) {
                              return _vm.incidentTypeSelected(type)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("v-loading"),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              attrs: { type: "hidden", name: "position" },
              domProps: {
                value:
                  _vm.incident.lat !== null && _vm.incident.long !== null
                    ? true
                    : null
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }