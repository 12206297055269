<template>
  <div class="flex flex-col">
    <nav class="flex pl-4 pr-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <router-link
        :to="{name: 'incidents-overview'}"
        class="w-1/3 flex justify-start items-center">
        <img
          src="@/assets/images/close.svg"
          :alt="$t('general.button_close')"
          class="w-7 h-7">
      </router-link>
      <p
        v-text="$t('the_incident_creation.button_add_report')"
        class="w-1/3 flex justify-center font-semibold pointer-events-none"
      ></p>
      <div class="w-1/3 flex justify-end">
        <button
          @click="onSubmit"
          class="btn btn-blue rounded-full py-1 px-2"
          :class="{'btn-loading': loading}"
          :disabled="loading"
          v-text="$t('general.button_save')"
        ></button>
      </div>
    </nav>
    <ul class="list-disc list-inside">
      <li
        v-if="error"
        v-text="error"
        class="form-error"></li>
      <li
        v-if="errors.has('position')"
        v-text="errors.first('position')"
        class="form-error"></li>
      <li
        v-if="errors.has('incident_type')"
        v-text="errors.first('incident_type')"
        class="form-error"
      ></li>
    </ul>
    <form
      @submit.prevent="onSubmit"
      class="flex-grow flex flex-col">
      <div class="flex-grow">
        <div class="form-fieldset">
          <p
            v-text="$t('the_incident_creation.label_title')"
            class="form-input-label"></p>
          <input
            type="text"
            name="title"
            class="form-input"
            autofocus
            ref="title"
            :placeholder="$t('general.placeholder_optional')"
            v-model="incident.title"
          >
        </div>
        <div class="form-fieldset mt-4">
          <p
            v-text="$t('the_incident_creation.label_report_type')"
            class="form-input-label text-gray-500"
          ></p>
        </div>
        <div v-if="incidentTypes !== null">
          <label
            class="detail-field"
            v-for="type in incidentTypes"
            :key="type.id">
            <div class="flex items-center">
              <svg
                viewBox="0 0 40 40"
                :style="{color: type.color}"
                class="fill-current w-7 h-7">
                <circle
                  cx="20"
                  cy="20"
                  r="20"></circle>
              </svg>
              <p
                v-text="type.name"
                class="detail-field-label ml-3"></p>
            </div>
            <v-radio
              name="incident_type"
              :validation="'required|min:1'"
              :checked="incident.incidentTypes.length > 0 &&
                type.id === incident.incidentTypes[0].id"
              @input="incidentTypeSelected(type)"
            />
          </label>
        </div>
        <v-loading v-else/>
        <input
          type="hidden"
          :value="(incident.lat !== null && incident.long !== null) ? true : null"
          name="position"
          v-validate="'required'"
        >
      </div>
    </form>
  </div>
</template>

<script>
  import Incident from '@/models/Incident';

  import { mapActions } from 'vuex';
  import validator from '@/mixins/validator';
  import EventBus from '@/eventbus';
  import VLoading from '@/components/common/VLoading.vue';
  import VRadio from '@/components/common/VRadio.vue';

  export default {
    name: 'TheIncidentCreation',
    components: {
      VRadio,
      VLoading,
    },
    mixins: [validator],
    data() {
      return {
        incident: new Incident(),
        error: null,
        loading: false,
      };
    },
    computed: {
      incidentTypes() {
        return this.$store.getters['incidentType/list'];
      },
    },
    watch: {
      incidentTypes() {
        const allowedIncidentTypes = this.incidentTypes.map(incidentType => incidentType.id);

        this.incident.incidentTypes = this.incident.incidentTypes.filter(
          incidentType => allowedIncidentTypes.includes(incidentType.id),
        );

        if (this.incidentTypes.length === 1 && this.incident.incidentTypes.length === 0) {
          this.incident.incidentTypes.push(this.incidentTypes[0]);
        }
      },
    },
    mounted() {
      EventBus.$on('new_incident_location_updated', (position) => {
        this.incident.lat = position.lat;
        this.incident.long = position.lng;
        this.$store.dispatch('incidentType/loadByLocation', {
          lat: position.lat,
          long: position.lng,
        });
      });

      EventBus.$on('new_incident_radius_updated', (radius) => {
        this.incident.radius = parseInt(radius, 10);
      });

      this.$refs.title.focus();
    },
    methods: {
      ...mapActions('incident', ['create']),
      onSubmit() {
        this.$validator.validate().then(() => {
          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;

          this.create(this.incident)
            .then((incident) => {
              this.$router.push({
                name: 'incident-detail',
                params: { id: `${incident.id}` },
              });
            })
            .catch((error) => {
              this.loading = false;

              if (error.response) {
                if (Object.keys(error.response.data.errors).length > 0) {
                  [[this.error]] = Object.values(error.response.data.errors);
                } else if (error.response.data.message) {
                  this.error = error.response.data.message;
                }
              } else {
                this.error = this.$t('general.label_error');
              }
            });
        });
      },
      incidentTypeSelected(type) {
        this.incident.incidentTypes = [type];
      },
    },
  };
</script>
